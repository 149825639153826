.login-container{
    height: 100h;
}

.login-inner-container{
    margin: 0;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 0;
}

.login-logo{
    width: 50%;
}

.error{
    color: red;
}