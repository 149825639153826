body{
    font-family: 'Poppins', sans-serif !important;
}

.sidebar-menu li .submenu{ 
	list-style: none; 
	margin: 0; 
	padding: 0; 
	padding-left: 1rem; 
	padding-right: 1rem;
}
.sidebar .nav li > a{
    margin: 5px 10px;
    padding: 0px;
    white-space: break-spaces;
    text-transform: capitalize;
    line-height: 1.5rem;
}
.navbar-collapse .nav-item a::before{
    content: "";
}
.nav-item a:hover{
    color: #0081FC !important;
    white-space: break-spaces;
}
.logo-img{
    width: 50px;
    height: 50px;
}

@media (max-width: 930px){
    #sidenav-main{
        position: fixed;
        top: 0px;
        z-index: 999;
        width: 100%;
    }
    #nav-button {
        display: block;
    }
}

.btn-home{
    background-color: #0081FC;
}

.header-container{
    padding: 10px 10px 5px 10px;
    background-color: #ffffff;
}
.empty-header-container{
    padding: 10px;
    background-color: #2E3440;
}
.code-bg{
    background-color: #000000;
}
.bg-blue{
    background-color: #f2f2f2 !important;
}

.text-red{
    color: red;
}
.overflow-table{
    overflow-x: auto;
}
table th{
    text-transform: capitalize !important;
    letter-spacing: 0px !important;
    font-size: 12px !important;
}
.main-panel{
    background-color: #ffffff !important;
}
.text-click{
    color: #0081FC;
    text-decoration: none;
    cursor: pointer;
    text-transform: none !important;
}
.text-click:hover{
    color: #0d83f3;
    text-decoration: none;
    cursor: pointer;
    text-transform: none !important;
}

.submenu {
	position: relative;
	list-style: none;
	margin-left: 0;
	padding-left: 1.2em;
}
.submenu li:before {
	content: ">";
	position: absolute;
	left: 0;
    padding-left: 1rem;
    color: #525f7f;
}
.submenu li:hover::before{
    color: #0081FC;
    content: ">";
}
.submenu li a{
    color: #525f7f;
    font-size: 14px;
    padding-left: 0.5rem;
    cursor: pointer;
}